#promotionStart, #promotionInside {
    min-height: calc(100vh - 174px);
    background-color: #145588;
    padding-top: 1px;
    padding-bottom: 1px;
}

#promotionInside iframe {
    min-height: calc(100vh - 174px);
}

#promotionStart h1 {
    text-align: center;
    color: white;
    margin: auto;
    margin-bottom: -6px;
}

#promotionStart h3 {
    font-family: avenir;
    text-transform: uppercase;
    text-align: center;
    font-weight: 1;
    color: white;
    margin-top: 0;
    margin-bottom: 30px;
}

.promotionStartPromo {
    cursor: pointer;
    user-select: none;
}

.promotionStartPromo:hover h1, .promotionStartPromo:hover h3 {
    color: #FFE400 !important;

}

#promotionStart > div > h1 {
    background-color: white;
    width: max-content;
    color: #145588;
    font-weight: 600;
    padding: 2px 10px;
    border-right: #b0bad8 solid 10px;
    border-left: #145588 solid 10px;
    margin: 40px auto;
}

#promotionStart > div > h1 strong {
    color: #b0bad8;
    font-size: 67px;
}



#promotionInside {
    width: 100%;
    max-width: 1000px;
    background-color: white;
    margin: auto;
    margin-top: -1px;
}

#promotionInside div h1, #promotionInside div h3 {
    color: black;
    font-weight: 600;
}

.promotionInsideArticles table {
    width: 100%;
    font-family: roboto;
}

.promotionInsideArticlesRef {
    font-size: 14px;
    width: 130px;
    text-align: center;
}

.promotionInsideArticlesName, .promotionInsideArticlesNameMobile {
    font-weight: 600;
    font-size: 17.4px;
}

.promotionInsidePriceOld {
    text-decoration: line-through;
    width: 80px;
    text-align: right;
}

.promotionInsidePriceNew {
    width: 80px;
    text-align: right;
    font-size: 17.4px;
    color: #145588;
    font-weight: 1000;
}

.promotionInsidePriceType {
    width: 100px;
    padding-left: 10px;
}

.promotionInsidePriceDescriptif {
    font-family: 'roboto';
    margin-left: 135px;
    margin-right: 210px;
    margin-top: 0;
}

#promotionInsidePDF {
    text-align: right;
    height: 0;
    padding-right: 50px;
    user-select: none;
    cursor: pointer;
}

.promotionInsideArticlesNameMobile {
    font-family: 'roboto';
    padding: 0 20px;
    display: none;
}

@media screen and (max-width: 1250px) {
    #promotionStart, #promotionInside {
        min-height: calc(100vh - 140px);
    }
}

@media screen and (max-width: 800px) {
    .promotionInsideArticlesName {
        display: none;
    }

    .promotionInsideArticlesNameMobile {
        display: block;
    }

    .promotionInsidePriceDescriptif {
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 640px) {
    #promotionInsidePDF {
        height: auto;
    }
}