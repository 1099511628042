#contact {
    background-color: #004887;
    min-height: 100vh;
}

#contact > div {
    background-color: white;
    min-height: 100vh;
    max-width: 800px;
    margin: auto;
    padding: 0px 60px;
}

#contact div *:not(h1):not(i) {
    font-size: 20px;
    font-family: 'roboto';

}

#contact div i {
    font-family: 'roboto';
    color: red;
    padding-top: 20px;
}

#contact div input, #contact div textarea {
    width: calc(100% - 44px);
    padding: 10px 20px;
}

#contact div .nameInput {
    width: calc(50% - 54px);
}

#contact div .nameInput:first-of-type {
margin-right: 20px;
}

#contact div textarea {
    resize: vertical;
    min-height: 200px;
}

#contact div button {
    background-color: white;
    border: 1px solid gray;
    padding: 10px 20px;
    margin: 20px 0px;
    cursor: pointer;
    transition: all 0.2s;
}

#contact div button:hover {
    background-color: black;
    color: white;
}

#contact div button:active {
    background-color: rgb(73, 73, 73);
}

@media screen and (max-width: 720px) {
    #contact > div {
        width: auto;
        padding: 0px 10px;
    }

    #contact div .nameInput {
        width: calc(100% - 44px);
        margin-bottom: 10px;
    }
}