footer {
    background-color: hsl(0, 0%, 38%);
    padding: 1px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

footer div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 20px;
}

footer button {
    background: none;
    border: none;
    color: #FFE400;
    font-size: 16px;
    cursor: pointer;
}

footer button:hover {
    text-decoration: underline;
}

footer p {
    color: white;
    font-family: 'roboto';
}