@font-face {
    font-family: "Garamond";
    src: url(../../font/Garamond.ttf);
}

@font-face {
    font-family: "HelveticaNeue";
    src: url(../../font/HelveticaNeue.ttc);
}

header {
    height: 204px;
    width: 100%;
    top: 0;
    -webkit-box-shadow: 0px 0px 44px 2px rgba(0,0,0,0.79); 
    box-shadow: 0px 0px 44px 2px rgba(0,0,0,0.79);
    background-color: white;
    z-index: 10000;
    position: relative;
}

.headerNavTitle {
    font-family: 'Garamond';
    font-weight: initial;
    font-size: 18px;
    margin: 20px;
    margin-bottom: 10px;
    margin-top: 0px;
    text-transform: uppercase;
}

.headerNavTitle::first-letter {
    font-size: 22px;
}

.BigHeaderNavbarSmallTitle a {
    margin: 0;
    padding: 10px 20px;
    padding-bottom: 15px;
    color: #BDB9A9;
    text-align: right;
    cursor: pointer;
    user-select: none;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-decoration: none;
}

.BigHeaderNavbarSmallTitle a:hover {
    color: white;
}

#title {
    width: 100%;
    
    border-spacing: 0;
    border-collapse: collapse;
}

#title td {
    padding: 0;
}

#titleTitle {
    text-align: center;
}

#titleTitle a:first-child img {
    width: 230px;
    padding-left: 80px;
    cursor: pointer;
}

#titleTitle a:last-child img {
    width: 120px;
    padding-bottom: 45px;
    cursor: pointer;
}

.titleMenu {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 60px;
}

#bigNavBar {
    margin: auto;
}

#bigNavBar td {
    padding: 0px 20px;
}

#bigNavBar h2 {
    user-select: none;
    cursor: pointer;
    color:#CAC7BA
}


#bigNavBar h2:hover {
    color: black;
}

#bigNavBar td > div {
    width: 0;
    height: 0;
    margin: auto;
}

#bigNavBar td > div > div {
    width: 232px;
    transform: translateX(-116px);
    background-color: #222021f2;
}

#smallNavBar {
    height: calc(100vh - 140px);
    width: 400px;
    max-width: 100vw;
    background-color: white;
    position: fixed;
    overflow: hidden scroll;
    top: 140px;
    border-top: 1px solid lightgrey;
    z-index: 1000;
}

.smallNavBarTitle {
    border-bottom: 1px solid lightgrey;
    color: #262626;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
}

.smallNavBarTitle h2 {
    margin: 0;
    padding: 15px 25px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 24px;
}

#smallNavBar a {
    margin: 0;
    padding: 13px 20px;
    background-color: #F6F6F6;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 20px;
    color: #3a3a3a;
    cursor: pointer;
    user-select: none;
    display: block;
    text-decoration: none;
}

#smallNavBar a:hover {
    color: black;
}

#headerLogoLong {
    display: none;
}

#headerContact {
    width: 200px;
    height: 30px;
    position: absolute;
    top: 100px;
    right: 0px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

#headerContact p {
    color: rgb(123, 114, 82);
    font-family: Garamond;
    font-size: 14px;
}

#headerContact img {
    margin: 30px;
    margin-left: 10px;
    width: 20px;
}

#headerGoToTop {
    position: fixed;
    width: 50px;
    top: calc(100vh + 40px);
    right: 20px;
    height: 100vh;
}

#headerGoToTop div {
    width: 50px;
    height: 50px;
    position: sticky;
    position: -webkit-sticky;
    top: calc(100vh - 100px);
    align-self: flex-start;
    border: 1px solid gray;
    border-radius: 100%;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.79);
    position: fixed;
}

#headerGoToTop div img {
    width: 40px;
    transform: translateY(-3px);
}

@media screen and (max-width: 1250px) {
    #bigNavBar {
        display: none;
    }

    header {
        height: 140px;
    }

    #title {
        height: 100%;
    }

    #titleTitle a:last-child img {
        padding-bottom: 0;
        padding-top: 18px;
    }

    #titleTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .boddyOverflowOn {
        overflow: hidden;
    }

    .headerFixed {
        position: fixed;
    }

    #titleTitle a:first-child img {
        width: 297px;
        padding-left: 0px;
        max-width: 60%;
        display: none;
    }

    #headerLogoLong {
        display: block;
        height: 96px;
        margin-right: 0;
    }

    #headerContact {
        display: none;
    }
}

@media screen and (min-width: 1250px) {
    .titleMenu, #smallNavBar {
        display: none;
    }
}

@media screen and (max-width: 640px) {

    #titleTitle a:last-child img {
        display: none;
    }
}