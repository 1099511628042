@font-face {
    font-family: "avenir";
    src: url(../font/Avenir-Light.ttf);
}

@font-face {
    font-family: "Garamond";
    src: url(../font/Garamond.ttf);
}

@font-face {
    font-family: "HelveticaNeue";
    src: url(../font/HelveticaNeue.ttc);
}

body {
    margin: 0;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

/*
** Polices et couleurs
*/

.cssCommun {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    width: 1386px;
    margin: auto;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    align-content: stretch;
}

h1 {
    color: rgb(123, 114, 82);
    font-size: 40px;
    font-family: avenir;
    font-weight: 1;
    text-align: right;
    margin: 0 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 30px;
    line-height: 50px;
}

/*
** Par carte
*/

.goldenCard {
    background-color: #7B7252;
    color: white;
    font-weight: 400;
    text-align: right;
}

.goldenCard h5 {
    font-family: Garamond;
    padding: 20px 30px;
    padding-bottom: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 200;
}

.goldenCard p {
    font-family: roboto;
    padding: 13px 30px;
    margin: 0;
    font-size: 14.7px;
    line-height: 20px;
}

.cssCommun .liste > p {
    margin: 6px 0;
    font-family: roboto;
    line-height: 20px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14.7px;
}

.grayCard {
    background-color: #E5E3DD;
}

.grayCard h2 {
    font-family: Garamond;
    color: rgb(123, 114, 82);
    font-weight: 400;
    line-height: 30px;
    font-size: 26px;
    margin: 20px 30px;
}

.grayCard h3 {
    font-family: Garamond;
    color: rgb(123, 114, 82);
    font-weight: 400;
    font-size: 20px;
    margin: 20px 30px;
}

.grayCard pre {
    margin-left: 30px;
    font-size: 14.7px;
    font-family: roboto;
    font-weight: 400;
    color: rgb(123, 114, 82);
}

.grayCard p {
    font-family: roboto;
    margin: 6px 30px;
    list-style: 20px;
    font-size: 14.7px;
    line-height: 20px;
}

.cssCommun .transparentCard {
    background-color: rgba(255,255,255,0.9);
}

.cssCommun .transparentCard p {
    font-family: roboto;
    font-weight: 400;
    font-size: 14.7px;
    margin: 6px 30px;
    line-height: 20px;
}

.cssCommun .transparentCard h1 {
    margin-left: 30px;
    color: rgb(0, 71, 138);
    text-align: left;
}

/*
** Mise en page
*/

.cssCommun .twoColone {
    display: flex;

}

.cssCommun .twoColone > div {
    width: 50%;
}
/*
** Mobile mise en page.
*/

@media screen and (max-width: 1420px) {
    .cssCommun {
        width: 693px;
    }
}

@media screen and (max-width: 700px) {
    .cssCommun h1 {
        text-align: center;
        margin: 30px 10px;
        padding-bottom: 20px;
        border-bottom: solid 3px rgb(123, 114, 82);
    }

    .cssCommun .hideMobile {
        display: none !important;
    }

    .cssCommun {
        width: 100%;
        display: block;
        margin-bottom: 50px;
        margin-top: 10px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-top: 0;
    }

    .cssCommun .undoTwoColone {
        display: block;
    }

    .cssCommun .undoTwoColone > div {
        width: auto !important;
    }

    .cssCommun .portableAlignCenter {
        text-align: center;
    }
}

@media screen and (min-width: 700px) {
    .cssCommun .hidePC, .hidePC {
        display: none;
    }
}